import React, { useContext } from 'react';
import clsx from 'clsx';

import styled from '@emotion/styled';
import { Button, Icon } from 'components';
import AddButton from './AddButton';
import RequestTypeLabel from './RequestTypeLabel';
import RequestsContext from '../context';
import dayjs from 'dayjs';
import { ContactRequest } from '../types';

interface RequestsListItemProps {
  data: ContactRequest;
}

const RequestsListItem: React.FC<RequestsListItemProps> = ({ data }) => {
  const {
    createdOn,
    name,
    email,
    status,
    type,
    tourType,
    source,
    propertyId,
    task,
    event,
    assignedTo
  } = data;

  const {
    setSelectedRequest,
    handleCompleteRequest,
    handleOpenTask,
    handleAssignUser,
    handleMarkAsRead,
    userOptions
  } = useContext(RequestsContext);

  const calendarItem = task || event;

  const handleClick = () => {
    setSelectedRequest(data);
    if (!data.read) handleMarkAsRead(data);
  };

  return (
    <StyledRequestsListItem
      onClick={handleClick}
      className={clsx('requests-item', { completed: status === 'Completed', new: !data.read })}>
      <div className="requests-item__col">
        <Button
          simple
          onClick={e => {
            e.stopPropagation();
            handleCompleteRequest(data);
          }}>
          <Icon name="check" width={16} height={16} />
        </Button>
      </div>
      <div className="requests-item__col">
        <span className="requests-item__value">{dayjs.unix(createdOn).format('h:mm A')}</span>
      </div>
      <div className="requests-item__col">
        <span className="requests-item__value">
          <RequestTypeLabel type={type} tourType={tourType} />
        </span>
      </div>
      <div className="requests-item__col listing">
        <span className="requests-item__value">{propertyId ? source : '-'}</span>
      </div>
      <div className="requests-item__col">
        <span className="requests-item__value">{name || '-'}</span>
      </div>
      <div className="requests-item__col">
        <span className="requests-item__value">{email || '-'}</span>
      </div>
      <div className="requests-item__col">
        <span
          className={clsx('requests-item__value', { task: Boolean(task), event: Boolean(event) })}>
          <AddButton
            add={!calendarItem}
            onClick={e => {
              e.stopPropagation();
              handleOpenTask(data);
            }}>
            {calendarItem ? calendarItem.title : `+ Add task`}
          </AddButton>
        </span>
      </div>
      <div className="requests-item__col">
        {assignedTo ? (
          <>
            <Icon name="user2" width={16} height={16} />
            <span className="requests-item__value">
              {userOptions.find(user => user.value === assignedTo)?.text}
            </span>
          </>
        ) : (
            <AddButton
              className="requests-item__value"
              add
              onClick={e => {
                e.stopPropagation();
                handleAssignUser(data);
              }}>
              + Assign to me
            </AddButton>
          )}
      </div>
    </StyledRequestsListItem>
  );
};

export default RequestsListItem;

const gray = props => props.theme.colors.gray;
const grayDark = props => props.theme.colors.grayDark;
const grayLight = props => props.theme.colors.grayLight;
const lightGreen = props => props.theme.colors.lightGreen;

const StyledRequestsListItem = styled.div`
  position: relative;
  margin: 6px 0 6px -15px;
  display: grid;
  grid-template-columns: 24px 0.75fr 1fr 2fr 1fr 1.75fr 1.5fr 1fr;
  column-gap: 10px;
  padding: 4px 4px 4px 15px;
  font-size: 12px;
  line-height: 16px;
  border-radius: ${props => props.theme.misc.borderRadius};
  cursor: pointer;

  &:hover {
    background: ${grayLight};
  }

  .requests-item__col {
    display: flex;
    align-items: center;
    overflow: hidden;

    & > span {
      text-overflow: ellipsis;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .listing {
    color: ${grayDark};
  }

  .task {
    position: relative;
    padding-left: 12px;
    font-weight: 500;
    &:before {
      content: '';
      position: absolute;
      width: 4px;
      left: 0;
      top: 0;
      bottom: 0;
      background: ${lightGreen};
      border-radius: 4px;
    }
  }

  .event {
    padding: 0 4px;
    color: #ffffff;
    background: ${props => props.theme.colors.blue};
    border-radius: ${props => props.theme.misc.borderRadius};
  }

  .icon {
    fill: ${gray};
    flex-shrink: 0;
    &.icon-user2 {
      margin: 0 6px 0 0;
    }
  }

  .icon-attachment {
    margin-right: 4px;
  }

  &.completed {
    .requests-item__value,
    .requests-item__value > * {
      opacity: 0.4;
      text-decoration: line-through;
    }
    .icon-star {
      fill: rgba(205, 205, 205, 0.4);
    }

    .icon-check {
      opacity: 1;
      fill: ${lightGreen};
    }
  }

  &.new {
    position: relative;
    &:before {
      content: '●';
      position: absolute;
      top: 10px;
      font-size: 8px;
      color: ${props => props.theme.colors.red};
    }
    .requests-item__value {
      font-weight: 700;
    }
  }
`;
