import React from 'react';
import styled from '@emotion/styled';
import ContentLoader from 'react-content-loader';

const RequestsSkeleton = () => {
  return (
    <StyledRequestsSkeleton className="emails-skeleton">
      {Array(12)
        .fill(undefined)
        .map((_, idx) => (
          <div key={idx} className="emails-skeleton-row">
            <ContentLoader speed={2} height="32">
              <rect x="0" y="0" width="12" height="12" rx="3" />
            </ContentLoader>
            <ContentLoader speed={2} height="32">
              <rect x="0" y="0" width="12" height="12" rx="3" />
            </ContentLoader>
            {Array(8)
              .fill(undefined)
              .map((_, idx) => (
                <ContentLoader speed={2} height="32" key={idx}>
                  <rect x="0" y="0" width="80%" height="12" rx="3" />
                </ContentLoader>
              ))}
          </div>
        ))}
    </StyledRequestsSkeleton>
  );
};

export default RequestsSkeleton;

const StyledRequestsSkeleton = styled.div`
  margin: 18px 0;

  .emails-skeleton-row {
    display: grid;
    grid-template-columns: 24px 24px 0.75fr 1fr 1fr 2fr 1.5fr 1.5fr 1fr 1fr;
    grid-column-gap: 10px;

    & > svg {
      width: 100%;
    }
  }
`;
