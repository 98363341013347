/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import useLayout from 'hooks/useLayout';
import styled from '@emotion/styled';
import dayjs, { Dayjs } from 'dayjs';
import { Icon } from 'components';
import RequestsListItem from './RequestsListItem';
import RequestsListItemMobile from './RequestsListItemMobile';
import RequestsSkeleton from './RequestsSkeleton';
import RequestsContext from '../context';

const getDays = ({ start, end }) => {
  const days: Dayjs[] = [];
  const startDate = dayjs.unix(start);
  let endDate = dayjs.unix(end);

  while (endDate.isAfter(startDate)) {
    days.push(endDate);
    endDate = endDate.subtract(1, 'day');
  }

  return days;
};

const RequestsList = () => {
  const { requests, filters, loading } = useContext(RequestsContext);
  const layout = useLayout();
  const days = getDays(
    {
      start: filters.startDate,
      end: filters.endDate
    });

  const ListItem = layout === 'desktop' ? RequestsListItem : RequestsListItemMobile;


  const getRequestsByDate = (day) => {
    const itemsByDate = requests.filter(request => dayjs.unix(request.createdOn).isSame(day, 'day'));
    return itemsByDate.length ? itemsByDate.map(item => (
      <ListItem data={item} key={item.id} />
    ))
      : (
        <span className="empty-result">No requests received yet.</span>
      );
  };

  const getLayout = () => {
    switch (layout) {
      case 'mobile':
      case 'tablet':
        return (
          <StyledRequestsListMobile className="requests-list-mobile">
            {loading ? <RequestsSkeleton /> : (
              days.map((day, idx) => (
                <React.Fragment key={idx}>
                  <div className="requests-list-date">
                    {day.format('MMM D')}
                    {day.isToday() ? ' - Today' : ''}
                  </div>
                  {getRequestsByDate(day)}
                </React.Fragment>
              ))
            )}

          </StyledRequestsListMobile>
        );

      default:
        return (
          <StyledRequestsList className="requests-list">
            <div className="requests-list__header">
              <div className="requests-list__header__col">
                <Icon name="check" width={8} height={8} />
              </div>
              <div className="requests-list__header__col">Time</div>
              <div className="requests-list__header__col">Type</div>
              <div className="requests-list__header__col">Listing</div>
              <div className="requests-list__header__col">Name</div>
              <div className="requests-list__header__col">Email</div>
              <div className="requests-list__header__col">Task / Event</div>
              <div className="requests-list__header__col">Assigned to</div>
            </div>
            {days.map((day, idx) => (
              <React.Fragment key={idx}>
                <div className="requests-list-date">
                  {day.format('MMM D')}
                  {day.isToday() ? ' - Today' : ''}
                </div>
                {getRequestsByDate(day)}
              </React.Fragment>
            ))}
          </StyledRequestsList>
        );
    }
  };

  return (
    <StyledRequestsList className="requests-list">{getLayout()}</StyledRequestsList>
  );
};

export default RequestsList;

const StyledRequestsList = styled.div`
  width: 100%;
  font-size: 12px;
  line-height: 16px;

  .requests-list-date {
    margin: 12px 0;
    font-weight: 500;
  }

  .requests-list__header {
    position: sticky;
    margin: 0 -32px;
    padding: 8px 32px;
    top: -32px;
    display: grid;
    grid-template-columns: 24px 0.75fr 1fr 2fr 1fr 1.75fr 1.5fr 1fr;
    column-gap: 10px;
    z-index: 1;
    grid-column: 1 / span 10;
    color: ${props => props.theme.colors.grayDark};
    background: #ffffff;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 32px;
      right: 32px;
      border-top: 1px solid ${props => props.theme.colors.alto};
    }

    .icon {
      fill: ${props => props.theme.colors.grayDark};
    }
  }

  .date {
    padding: 12px 0;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  .empty-result {
    display: block;
    margin-bottom: 4px;
    color: ${props => props.theme.colors.grayDark};
  }
  .requests {
    &__header {
      margin: 0 0 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__title,
    &__title2 {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: ${props => props.theme.colors.black};
      margin: 0;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .requests-list-date {
      margin: 12px 0 4px 0;
    }
  }
`;

const StyledRequestsListMobile = styled.div`
  width: 100%;

  .requests-list__header {
    position: sticky;
    margin: 0 -32px;
    padding: 8px 32px;
    top: -32px;
    display: grid;
    grid-template-columns: 24px 24px 0.75fr 1fr 1fr 2fr 1.5fr 1.5fr 1fr 1fr;
    column-gap: 10px;
    z-index: 1;
    grid-column: 1 / span 10;
    font-size: 12px;
    line-height: 16px;
    color: ${props => props.theme.colors.grayDark};
    background: #ffffff;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 32px;
      right: 32px;
      border-top: 1px solid ${props => props.theme.colors.alto};
    }

    .icon {
      fill: ${props => props.theme.colors.grayDark};
    }
  }

  .date {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 12px;

    .date-text {
      margin-bottom: 3px;
    }
  }
`;
