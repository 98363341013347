import React, { useContext } from 'react';
import { capitalize } from 'lodash';
import styled from '@emotion/styled';
import MainLayout from 'components/MainLayout';
import { ModalConfirm } from 'components';
import TaskForm from 'workspace/WorkspaceTasksTab/WorkspaceEventEditModal';
import Filters from './components/Filters';
import RequestsList from './components/RequestsList';
import RequestModal from './components/RequestModal';
import RequestsContext, { RequestsContextWrapper } from './context';
import { ContactRequestCalendar } from './types.d';

export const RequestsPage = () => {
  const {
    taskData,
    setTaskData,
    handleEditTask,
    selectedRequest,
    setSelectedRequest,
    userOptions,
    documentOptions,
    calendarDelete,
    setCalendarDelete,
    handleDeleteCalendar
  } = useContext(RequestsContext);

  const taskValues = taskData?.event || taskData?.task || {} as ContactRequestCalendar;
  const taskFormData = { ...taskValues, propertyName: taskData?.source };

  return (
    <MainLayout>
      <StyledRequestsPage className="requests">
        <div className="requests__header">
          <h4 className="requests__title">Contact Requests</h4>
          <Filters />
        </div>
        <RequestsList />
        <RequestModal
          title={selectedRequest?.type}
          open={Boolean(selectedRequest)}
          onClose={() => setSelectedRequest(undefined)}
        />
      </StyledRequestsPage>
      <TaskForm
        open={Boolean(taskData)}
        data={taskFormData}
        propertyId={taskData?.propertyId}
        dateType={taskFormData?.dateType}
        documentsOptions={documentOptions}
        usersOptions={userOptions}
        onClose={() => setTaskData(undefined)}
        onDeleteClick={setCalendarDelete}
        onEditSuccess={handleEditTask}
        hidePersonalEvent
      />
      <ModalConfirm
        open={Boolean(calendarDelete)}
        modalTitle={`Delete ${calendarDelete?.dateType}`}
        onClose={() => setCalendarDelete(undefined)}
        onSubmit={() => handleDeleteCalendar(calendarDelete)}>
        <p>{`${capitalize(
          calendarDelete?.dateType
        )} will be removed completely from the system.`}</p>
      </ModalConfirm>
    </MainLayout>
  );
};

export default props => (
  <RequestsContextWrapper>
    <RequestsPage {...props} />
  </RequestsContextWrapper>
);

const StyledRequestsPage = styled.div`
  .requests {
    &__header {
      margin: 0 0 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      &__header {
        margin: 0 0 16px;
      }
      &__title {
        margin-bottom: 0;
      }
    }
  }
`;
