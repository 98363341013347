import React, { useState, useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import pluralize from 'pluralize';

import useLayout from 'hooks/useLayout';
import { Button, Dropdown, Icon, Modal, SelectDates } from 'components';
import RequestsContext from '../context';

const statusOptions = [
  { value: 'Not completed', text: 'Not completed' },
  { value: 'Completed', text: 'Completed' },
];

const getDateLabel = ({ start, end }) => {
  if (!start || !end) return '';
  if (dayjs.unix(start).isSame(dayjs.unix(end), 'day')) return dayjs.unix(start).format('MMM D');
  return `${dayjs.unix(start).format('MMM D')} - ${dayjs.unix(end).format('MMM D')}`;
};

const Filters = () => {
  const [openDatepicker, setOpenDatepicker] = useState<boolean>(false);
  const [openFiltersModal, setOpenFiltersModal] = useState<boolean>(false);
  const { filters, setFilters, userOptions, listingOptions } = useContext(RequestsContext);
  const { status, listings, assignedTo } = filters;
  const layout = useLayout();

  const onDatesSelect = ({ start, end }) => {
    const startDate = start ? dayjs(start).startOf('d').unix() : undefined;
    const endDate = end ? dayjs(end).endOf('d').unix() : undefined;
    setFilters(v => ({ ...v, startDate, endDate }));
  };

  const statusFilterText = useMemo(() => {
    if (!status?.length || status.length === statusOptions.length) return 'All';
    if (status?.length === 1) {
      return (statusOptions?.find(item => item.value === status[0])?.value);
    }
  }, [status, statusOptions]);

  const listingsFilterText = useMemo(() => {
    if (!listings?.length || listings.length === listingOptions.length) return 'All';
    return `${listings.length} ${pluralize('listing', listings?.length)}`;
  }, [listings, listingOptions]);

  const assignedToFilterText = useMemo(() => {
    if (!assignedTo?.length || assignedTo.length === userOptions.length) return 'All';
    return `${assignedTo.length} ${pluralize('user', assignedTo?.length)}`;
  }, [assignedTo, userOptions]);

  const filtersContent = useMemo(() => (
    <>
      <Dropdown
        options={statusOptions}
        value={filters.status}
        onChange={status => setFilters(v => ({ ...v, status: status }))}
        label="Status:"
        text={statusFilterText}
        optionAll
        multiple
        buttonLike
      />
      <Dropdown
        options={listingOptions}
        value={filters.listings}
        onChange={listings => setFilters(v => ({ ...v, listings: listings }))}
        label="Listings:"
        text={listingsFilterText}
        optionAll
        search
        multiple
        buttonLike
      />
      <StyledDateButton onClick={() => setOpenDatepicker(v => !v)} simple>
        <Icon name="calendar" />&nbsp;
              <label>Date:</label>&nbsp;
              {getDateLabel({ start: filters.startDate, end: filters.endDate })}
        <Icon name="chevron" />
      </StyledDateButton>
      <Dropdown
        options={userOptions}
        value={filters.assignedTo}
        onChange={users => setFilters(v => ({ ...v, assignedTo: users }))}
        label="Assigned to:"
        text={assignedToFilterText}
        search
        optionAll
        multiple
        buttonLike
      />
    </>
  ), [filters, userOptions, listingOptions, statusOptions]);

  const getLayout = () => {
    switch (layout) {
      case 'mobile':
      case 'tablet':
        return (
          <>
            <Button
              className="button-filters"
              secondary
              onClick={() => setOpenFiltersModal(true)}>
              <Icon name="filter" width={14} height={12} />
            </Button>
            <StyledFiltersModal
              modalTitle="Filters"
              open={openFiltersModal}
              onClose={() => setOpenFiltersModal(false)}>
              {filtersContent}
            </StyledFiltersModal>
          </>
        );

      case 'desktop':
        return filtersContent;
    }
  };

  return (
    <StyledFilters className="filters">
      {getLayout()}
      <SelectDates
        start={new Date(filters.startDate * 1000)}
        end={new Date(filters.endDate * 1000)}
        open={openDatepicker}
        onSubmit={value => {
          onDatesSelect(value);
          setOpenDatepicker(false);
        }}
        onClose={() => setOpenDatepicker(false)}
      />
    </StyledFilters>
  );
};

export default Filters;

const StyledFilters = styled.div`
  display: flex;
  align-items: center;
  
  .dropdown ~ .dropdown {
    margin-left: 12px;
  }

  .dropdown-options {
    width: 220px;
    min-width: 220px;
    label {
      white-space: normal;
    }
  }
`;

const StyledFiltersModal = styled(Modal)`
  .modal-body {
    display: flex;
    flex-direction: column;

    .dropdown,
    .button {
      margin-left: 0;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .date-btn {
      justify-content: flex-start;

      .icon-chevron {
        margin-left: auto;
      }
    }
  }
`;

const StyledDateButton = styled(Button)`
  &.simple {
    margin-left: 12px;
    height: 32px;
    padding: 8px;
    border: 1px solid #000;
    border-radius: ${props => props.theme.misc.borderRadius};
    justify-content: flex-start;
    .icon-chevron {
      margin-left: auto;
    }
  }
`;
