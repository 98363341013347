import React, { useContext } from 'react';
import clsx from 'clsx';

import styled from '@emotion/styled';
import RequestTypeLabel from './RequestTypeLabel';
import RequestsContext from '../context';

const unreadIndicator = (
  <svg
    className="unread-indicator"
    width="4"
    height="4"
    viewBox="0 0 4 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle cx="2" cy="2" r="2" fill="#DF1721" />
  </svg>
);

const RequestsListItemMobile = ({ data, ...props }) => {
  const { status, read, email, name, time, listingId, source, type, tourType } = data;
  const { setSelectedRequest, handleMarkAsRead } = useContext(RequestsContext);

  const handleClick = () => {
    setSelectedRequest(data);
    if (!read) handleMarkAsRead(data);
  };

  return (
    <StyledRequestsListItemMobile
      onClick={handleClick}
      className={clsx('requests-item', { unread: !read, completed: status === 'Completed' })}
      {...props}>
      <div className="requests-item__row">
        {!read && unreadIndicator}
        <div className="name">{name}</div>
        <div className="time">{time}</div>
        <div className="type">
          <RequestTypeLabel type={type} tourType={tourType} />
        </div>
      </div>
      {listingId && (
        <div className="requests-item__row">
          <div className="property-address">{source}</div>
        </div>
      )}
      <div className="requests-item__row">
        <div className="email">{email}</div>
      </div>
    </StyledRequestsListItemMobile>
  );
};

export default RequestsListItemMobile;

const gray = props => props.theme.colors.gray;
const grayDark = props => props.theme.colors.grayDark;
const grayLight = props => props.theme.colors.grayLight;

const StyledRequestsListItemMobile = styled.div`
  display: grid;
  column-gap: 10px;
  row-gap: 8px;
  font-size: 14px;
  line-height: 16px;
  padding: 12px 10px;
  border-bottom: 1px solid ${props => props.theme.colors.seashell};
  cursor: pointer;
  margin: 0 -10px;

  &.unread {
    .name,
    .subject,
    .property-address {
      font-weight: 600;
    }
  }

  &.completed {
    .requests-item__row {
      * {
        text-decoration: line-through;
      }
    }
  }

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background: ${grayLight};
  }

  .requests-item__row {
    display: flex;
    align-items: center;

    .unread-indicator {
      margin: 0 3px 0 -6px;
    }
    .email {
      font-weight: 600;
    }
  }

  .name {
    margin-right: 6px;
  }

  .time {
    margin-right: auto;
    color: ${grayDark};
  }

  .button-favorite {
    fill: ${gray};
  }

  .button-favorite {
    margin-right: 12px;

    .icon-star-fill {
      fill: #ffd53f;
    }
  }

  .subject {
    fill: ${grayDark};
    margin-right: 6px;
  }

  .property-address {
    color: ${grayDark};
    font-size: 12px;
    line-height: 16px;
  }

  .property-address {
    margin-right: auto;
  }

  .assigned-info {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 7px;
    }
  }
`;
